import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpResponse  } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CasosService {
  readonly URL_API = 'https://sugode2-back-node-staging.herokuapp.com/api/v1/'

  constructor(private http: HttpClient
    ) { }

  getCaso(radicado: string) {
    return this.http.get(`${this.URL_API}radicado/${radicado}`);
  }
}
