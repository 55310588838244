import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CasosService } from '../../services/casos.service'
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [CasosService]
})
export class DashboardComponent implements OnInit {
  public consecutivo
  public date_create
  public movimientos
  public estado
  public order
  public consecutivoQuery
  public caso
  constructor(
    public casosServices: CasosService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.parametrosQuery()
    this.consultaParams()
  }

  parametrosQuery() {
    this.route.queryParams.subscribe(params => {
      this.consecutivoQuery = params['consecutivo'];
    })
  }

  consultaParams() {
    if (this.consecutivoQuery) {
      this.casosServices.getCaso(this.consecutivoQuery)
      .subscribe((res: any) => {
        this.consecutivo = res.consecutivo
        this.date_create = res.date_create
        this.movimientos = res.variables_history
        if (res.status === 'EJECUTANDOSE' || res.status === 'BORRADOR' || res.status === 'INICIAL') {
          this.estado = 'EN PROCESO'
        } else {
          this.estado = 'CERRADO'
        }
      })
    }
  }

  consultaForm(form: NgForm) {
    this.casosServices.getCaso(form.value.radicado)
    .subscribe((res: any) => {
      this.consecutivo = res.consecutivo
      this.date_create = res.date_create
      this.movimientos = res.variables_history
      if (res.status === 'EJECUTANDOSE' || res.status === 'BORRADOR' || res.status === 'INICIAL') {
        this.estado = 'En Proceso'
      } else {
        this.estado = 'Cerrado'
      }
    })
  }
}
