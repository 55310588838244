import { BrowserModule } from '@angular/platform-browser'
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common'
import { NgModule, LOCALE_ID } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { MenuComponent } from './components/menu/menu.component'
import { DashboardComponent } from './components/dashboard/dashboard.component'
import { FormsModule }   from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';


export function HttpLoaderFactory(http: HttpClient) {
}
registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    DashboardComponent
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-CO' } 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
